import { useMaskedHookFormInput } from "@asantech/common/react/common/hooks/useMaskedHookFormInput";
import { normalizePlateText } from "@asantech/common/react/common/plateTextAdapters";
import { Trans } from "@lingui/macro";
import { useAsync } from "@react-hookz/web";
import { getSpaces } from "api/spaces";
import { Pages } from "common/pages";
import { Plate } from "common/types";
import { Button } from "components/Button";
import { Content } from "components/Content";
import { BrandAwareFooter } from "components/Footer";
import { Header } from "components/Header";
import { Label } from "components/Label";
import { PlateCountryField } from "components/PlateCountryField";
import { PlateTextField } from "components/PlateTextField";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Container,
  WidthContainer,
  WidthContainerNarrow,
} from "../components/Container";

type FormData = Plate;

const combineUrl = ({ plateText, plateCountry = "" }: Plate) =>
  `${Pages.Space}/${plateText}${plateCountry ? "-" : ""}${plateCountry}`;

export const SpacesPage = () => {
  const history = useHistory();
  const { plate: plateParam } = useParams<{ plate?: string }>();
  const [plateText, plateCountry] = (plateParam || "").split("-");
  const [
    { status, result, error },
    { execute: fetchSpaces, reset: resetSpaces },
  ] = useAsync(getSpaces);
  const isLoading = status === "loading";
  const { control, formState, register, reset, handleSubmit } =
    useForm<FormData>({
      mode: "all",
      defaultValues: { plateText, plateCountry },
    });
  const textRegister = useMaskedHookFormInput(normalizePlateText, register);
  const handleCheckSpacesClick = async (data: FormData) => {
    history.push(combineUrl(data));
  };
  const handleBack = async () => history.goBack();
  const showResults = !isLoading && result && result.countries.length < 2;
  const resultCountry =
    result?.countries.length === 1 ? result.countries[0] : "";

  useEffect(() => {
    if (!plateText) return;
    if (resultCountry === plateCountry) return;
    fetchSpaces({ plateText, plateCountry });
  }, [fetchSpaces, plateText, plateCountry, resultCountry]);

  useEffect(() => {
    if (plateText && result?.countries.length === 1) {
      history.replace(
        combineUrl({ plateText, plateCountry: result.countries[0] })
      );
    }
  }, [result, plateText, history]);

  useEffect(() => {
    reset({ plateText, plateCountry });
    if (!plateText) resetSpaces();
  }, [plateText, plateCountry, resetSpaces, reset]);

  return (
    <Container>
      <Header />
      <Content>
        <SearchContainer>
          {!showResults && (
            <Label>
              <Trans>Check available contract parking space.</Trans>
              <br />
              <Trans>Enter your registration to continue:</Trans>
            </Label>
          )}
          <fieldset>
            <PlateTextField
              register={textRegister}
              formState={formState}
              readOnly={!!plateText}
              loading={!result}
              hasResults={!!result?.spaces.length}
              isSubmitted={!!result}
            />
            {result && result?.countries.length > 1 && (
              <>
                <Label>
                  <Trans>Country:</Trans>
                </Label>
                <PlateCountryField
                  control={control}
                  countryCodes={result?.countries || []}
                  invalid={!!formState.errors.plateCountry}
                />
              </>
            )}
          </fieldset>
          {!plateText || (result?.countries.length || 0) > 1 || isLoading ? (
            <SpacedButton
              onClick={handleSubmit(handleCheckSpacesClick)}
              loading={isLoading}
              stretch
            >
              <Trans>Continue</Trans>
            </SpacedButton>
          ) : (
            ""
          )}
          {showResults && result.spaces.length <= 0 && (
            <SpacedButton stretch variant="secondary" onClick={handleBack}>
              <Trans>Back</Trans>
            </SpacedButton>
          )}
          {!isLoading && error && (
            <InfoText>
              <Trans>Error fetching contract parking spaces</Trans>
            </InfoText>
          )}
          {showResults && result.spaces.length <= 0 && (
            <InfoText>
              <Trans>
                It seems you don&apos;t have contract parking spaces
              </Trans>
            </InfoText>
          )}
        </SearchContainer>
        <WidthContainer>
          {showResults && result.spaces.length > 0 && (
            <>
              <SpacesText>
                {result.spaces.length > 1 ? (
                  <Trans>
                    You have contract parking spaces available in{" "}
                    {result.spaces.length} locations
                  </Trans>
                ) : (
                  <Trans>
                    You have contract parking spaces available in 1 location
                  </Trans>
                )}
              </SpacesText>
              <BookmarkText>
                <Trans>Bookmark this page to check again later.</Trans>
              </BookmarkText>
              <Spaces>
                {result.spaces.map((s) => (
                  <Space key={s.hallName}>
                    <AvailableSpaces warn={s.spaces <= 0}>
                      {s.spaces <= 0 && (
                        <Trans>
                          NO <Tag>SPACES</Tag>
                        </Trans>
                      )}
                      {s.spaces === 1 && (
                        <Trans>
                          1 <Tag>SPACE</Tag>
                        </Trans>
                      )}
                      {s.spaces > 1 && (
                        <Trans>
                          {s.spaces} <Tag>SPACES</Tag>
                        </Trans>
                      )}
                    </AvailableSpaces>
                    <Location>
                      <Tag>
                        <Trans>LOCATION</Trans>
                      </Tag>
                      {s.hallName}
                    </Location>
                  </Space>
                ))}
              </Spaces>
            </>
          )}
        </WidthContainer>
      </Content>
      <BrandAwareFooter />
    </Container>
  );
};

const SearchContainer = styled(WidthContainerNarrow)`
  margin-bottom: 62px;
`;

const SpacesText = styled.h2`
  font-size: var(--fs-biggish);
  line-height: 150%;
  font-weight: normal;
  color: var(--text-highlight);
`;

const BookmarkText = styled.span`
  font-size: var(--fs-medium);
  line-height: 130%;
`;

const InfoText = styled.span`
  text-align: center;
  font-size: var(--fs-biggish);
  color: var(--text-highlight);
}
`;

const Spaces = styled.ul`
  margin: 0;
  margin-top: 36px;
  padding: 0;
`;

const Space = styled.li`
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-radius: 16px;
  display: grid;
  grid-template-columns: 204px 1fr;
  list-style-type: none;
  margin-bottom: 36px;
`;

const AvailableSpaces = styled.div<{ warn?: boolean }>`
  font-size: var(--fs-big);
  line-height: 85%;
  font-weight: bold;
  position: relative;
  min-height: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 2px solid rgba(255, 255, 255, 0.25);
  padding: 2px 0 0;
  color: var(--text-highlight);

  &:before {
    content: " ";
    position: absolute;
    left: 35px;
    top: 50%;
    margin-top: -8px;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background: ${(props) => (props.warn ? "#FAD200" : "#00FA14")};
  }
`;

const Location = styled.div`
  font-size: var(--fs-big);
  line-height: 85%;
  word-break: break-all;
  padding: 14px 68px;
  color: var(--text-highlight);
`;

const Tag = styled.div`
  font-size: var(--fs-medium);
  font-weight: normal;
  color: var(--text-primary);
`;

const SpacedButton = styled(Button)`
  margin: 40px 0;
`;
