import { openApiId, openApiUuid } from "../../common/types";
import { Encrypted, openApiEncrypted } from "../common/types";
import {
  Contract,
  ContractRequest,
  openApiContract,
  openApiContractInput,
} from "../contracts/types";

export type Company = {
  id: number;
  isPerson: boolean;
  name: Encrypted;
  email: Encrypted | null;
  operatorId: string;
};

export type CompanyRequest = {
  name: Encrypted;
  isPerson: boolean;
  email?: Encrypted | null;
};

export type CompanyInput = CompanyRequest & {
  operatorId: string;
};

export type CompanyResponse = Company;

export type CompanyWithContractInput = {
  company: CompanyRequest;
  contract?: Omit<ContractRequest, "companyId">;
};

export type CompanyWithContract = {
  company: CompanyResponse;
  contract: Contract;
};

export const openApiCompanyInputBaseProps = {
  isPerson: {
    type: "boolean",
  },
  name: {
    ...openApiEncrypted,
    description: "Name of the company.",
  },
  email: {
    ...openApiEncrypted,
    nullable: true,
  },
};

export const openApiCompanyInput = {
  type: "object",
  required: ["name", "isPerson"],
  oneOf: [
    {
      properties: {
        ...openApiCompanyInputBaseProps,
        isPerson: {
          type: "boolean",
          enum: [false],
        },
      },
    },
    {
      properties: {
        ...openApiCompanyInputBaseProps,
        isPerson: {
          type: "boolean",
          enum: [true],
        },
      },
    },
  ],
};

export const openApiCompany = {
  type: "object",
  required: [...openApiCompanyInput.required, "id", "operatorId"],
  properties: {
    ...openApiCompanyInputBaseProps,
    id: openApiId,
    operatorId: openApiUuid,
  },
};

export const openApiCompanyInputWithContract = {
  type: "object",
  required: ["company"],
  properties: {
    company: openApiCompanyInput,
    contract: {
      type: "object",
      required: openApiContractInput.required.filter((k) => k !== "companyId"),
      properties: openApiContractInput.properties,
    },
  },
};

export const openApiCompanyWithContract = {
  type: "object",
  required: openApiCompanyInputWithContract.required,
  properties: {
    company: openApiCompany,
    contract: {
      required: openApiContract.required.filter((k) => k !== "companyId"),
      properties: openApiContract.properties,
    },
  },
};
