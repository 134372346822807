import { useCallback } from "react";
import styled from "styled-components";

export type VirtualKeyboardProps = {
  value: string;
  setValue: (value: string) => void;
  email?: boolean;
};

type KeyProps = {
  onClick: (key: string) => void;
  children: string;
  wScale?: number;
  fitKeysPerRow: number;
};

const width = 800;
const fitKeysPerRow = 11;

const keysByRow = (rows: string[]) => rows.map((row) => row.split(""));

const fiKeyRows = ["1234567890", "QWERTYUIOPÅ", "ASDFGHJKLÖÄ", "ZXCVBNM⟵"];
const fiKeysByRow = keysByRow(fiKeyRows);

const fiEmailKeyRows = [
  "@._%+-1234567890",
  "QWERTYUIOPÅ",
  "ASDFGHJKLÖÄ",
  "ZXCVBNM⟵",
].map((row) => row.toLowerCase());
const fiEmailKeysByRow = keysByRow(fiEmailKeyRows);

const KeyboardKey = ({ onClick, ...otherProps }: KeyProps) => {
  const { children } = otherProps;
  const handlePress = useCallback(() => onClick(children), [children, onClick]);

  return <Key {...otherProps} onClick={handlePress} />;
};

export const VirtualKeyboard = ({
  value,
  setValue,
  email,
}: VirtualKeyboardProps) => {
  const handleKeyPress = useCallback(
    (key: string) => {
      const newValue =
        key === "⟵" ? value.substring(0, value.length - 1) : value + key;

      setValue(newValue);
    },
    [value, setValue]
  );

  const keysByRow = email ? fiEmailKeysByRow : fiKeysByRow;

  return (
    <Keyboard>
      {keysByRow.map((keys) => (
        <Row key={keys.join("")}>
          {keys.map((k) => (
            <KeyboardKey
              onClick={handleKeyPress}
              wScale={k === "⟵" ? 2 : 1}
              key={k}
              fitKeysPerRow={fitKeysPerRow}
            >
              {k}
            </KeyboardKey>
          ))}
        </Row>
      ))}
    </Keyboard>
  );
};

const Keyboard = styled.div`
  max-width: ${width}px;
  margin: 0 auto;
`;

const Row = styled.div`
  display: flex;
`;

const Key = styled.a<{ fitKeysPerRow: number; wScale?: number }>`
  max-width: ${(p) =>
    (p.wScale || 1) * (width / p.fitKeysPerRow - 2) +
    ((p.wScale || 1) - 1) * 2}px;
  height: 60px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
  background-color: var(--input-bg);
  margin: 1px;
  cursor: pointer;
  user-select: none;

  &:active {
    background-color: var(--secondary);
  }
`;
