import { HallDb } from "common/models/halls/types";
import { openApiId, openApiUuid } from "../../common/types";
import { Company, openApiCompanyInputBaseProps } from "../companies/types";
import { openApiProviderAssignmentBase } from "../providers/types";
import { openApiUser } from "./userBaseTypes";
export { UserStatusType } from "@aws-sdk/client-cognito-identity-provider";

export enum UserType {
  Provider = "provider",
  Operator = "operator",
  Company = "companyUser",
  ParkingManager = "parkingManager",
}

export enum UserGroup {
  Manager = "manager",
  ContractProvider = "contractProvider",
}

export const UserTypeGroups: { [key in UserType | UserGroup]: string } = {
  [UserType.Provider]: "provider",
  [UserType.Operator]: "operator",
  [UserType.Company]: "company",
  [UserType.ParkingManager]: "hall",
  [UserGroup.Manager]: "manager",
  [UserGroup.ContractProvider]: "contractProvider",
};

export const isUserType = (
  value: string | null | undefined
): value is UserType => Object.values(UserType).includes(value as UserType);

export type User = {
  sub?: string;
  operatorId?: string;
  providerId?: string;
  companyUserId?: string;
  companyId?: number;
  companyOperatorId?: string;
  hallId?: string;
  hallOperatorId?: string;
  managerId?: string;
  contractProviderId?: string;
};

export type ProviderRoleAttributes = { providerId: string };
export type OperatorRoleAttributes = { operatorId: string };
export type ManagerRoleAttributes = { managerId: string };
export type CompanyUserRoleAttributes = {
  companyUserId: number;
  companyId: number;
  companyOperatorId: string;
};
export type ParkingManagerRoleAttributes = {
  hallId: string;
  hallOperatorId: string;
};
export type ContractProviderRoleAttributes = { contractProviderId: string };

export type Provider = User & ProviderRoleAttributes;
export type Operator = User & OperatorRoleAttributes;
export type CompanyUser = User & CompanyUserRoleAttributes;
export type ParkingManager = User & ParkingManagerRoleAttributes;
export type Manager = User & ManagerRoleAttributes;
export type ContractProvider = User & ContractProviderRoleAttributes;

export type AuthenticatedUser =
  | Operator
  | Provider
  | CompanyUser
  | ParkingManager
  | Manager
  | ContractProvider;

export type CompanyUserInvite = {
  email: string;
  companyId: string;
};

export type UserRequest = {
  email: string;
  isNew?: boolean;
  isInvited?: boolean;
};

export type UserResponse = {
  sub: string;
  email: string;
  name?: string;
  hallId?: string;
  isInvited: boolean;
  error?: string;
};

export type UpdateUsersResponse = {
  targetUsers: UserResponse[];
  addedUsers: UserResponse[];
  deletedUsers: UserResponse[];
};

export type UserAndKeyResponse = UserResponse & {
  hasOperatorKey: boolean;
};

export type UserByType =
  | {
      operator: { id: string };
      users: UserAndKeyResponse[];
    }
  | {
      company: Company;
      users: UserAndKeyResponse[];
    }
  | {
      parkingManager: { operatorId: string };
      users: UserAndKeyResponse[];
    };

export type OperatorCreateInput = {
  email: string;
};

export type OperatorInvitationInput = {
  operatorId: string;
  email: string;
  managerId: string;
};

export type OperatorInvitationDb = OperatorInvitationInput & {
  sentAt: Date;
  confirmedAt: Date;
};

export type OperatorNotConfirmedInvitation = OperatorInvitationInput & {
  sentAt: string;
};

export type ParkingManagerCreateInput = OperatorCreateInput & {
  hallId: HallDb["id"];
  name: string;
};

export type CompanyUserCreateInput = OperatorCreateInput & {
  companyId: Company["id"];
  name: string;
};
export const openApiOperatorInvite = {
  type: "object",
  required: ["email"],
  properties: {
    email: {
      type: "string",
      format: "email",
      description: "Email to send invite to",
    },
  },
};

export const openApiParkingManagerInvite = {
  type: "object",
  required: ["email", "hallId"],
  properties: {
    email: {
      type: "string",
      format: "email",
      description: "Email to send invite to",
    },
    hallId: {
      type: "string",
      format: "uuid",
      description: "Id of the hall user has access to",
    },
    name: {
      type: "string",
      description: "Name of the parking manager",
    },
  },
};

export const openApiCompanyUserInvite = {
  type: "object",
  required: ["email", "companyId"],
  properties: {
    email: {
      type: "string",
      format: "email",
      description: "Email to send invite to",
    },
    companyId: {
      type: "integer",
      minimum: 1,
      description: "Id of the company user has write access to",
    },
  },
};

export const openApiOperatorInvitation = {
  type: "object",
  required: ["operatorId", "email", "managerId", "sentAt"],
  properties: {
    operatorId: openApiUuid,
    email: {
      type: "string",
      format: "email",
      description: "Email invite has been sent to",
    },
    managerId: openApiUuid,
    sentAt: {
      type: "string",
      format: "date-time",
      description: "Time when the last invite was sent",
    },
  },
};

export const openApiUserAndKeyResponse = {
  type: "object",
  properties: {
    ...openApiUser.properties,
    hasOperatorKey: {
      type: "boolean",
    },
  },
};

const openApiUsersAndKeyArray = {
  type: "array",
  items: openApiUserAndKeyResponse,
};

export const openApiUserByType = {
  type: "object",
  oneOf: [
    {
      properties: {
        operator: {
          type: "object",
          properties: {
            id: openApiUuid,
            users: openApiUsersAndKeyArray,
          },
        },
      },
    },
    {
      properties: {
        company: {
          type: "object",
          properties: {
            ...openApiCompanyInputBaseProps,
            id: openApiId,
            users: openApiUsersAndKeyArray,
          },
        },
      },
    },
  ],
};

export interface UserDb {
  id: string;
  cognitoId: string;
  syncedWithPool: Date;
  email: string;
  confirmed: boolean;
  missingFromPoolSince?: Date | null;
  name?: string | null;
  operatorId?: string | null;
  providerId?: string | null;
  companyUserId?: number | null;
  companyId?: number | null;
  companyOperatorId?: string | null;
  hallId?: string | null;
  hallOperatorId?: string | null;
  contractProviderId?: string | null;
}

export type UserDbInput = Omit<UserDb, "id">;

export type OperatorDb = {
  id: string;
  name: string;
  rivertyApiClientId?: string;
  rivertyInvoiceFee: number;
  healthNotificationsUrl?: string;
};

export const openApiOperatorSettings = {
  type: "object",
  required: [],
  properties: {
    name: {
      type: "string",
      minLength: 1,
      maxLength: 255,
      description: "Operator name",
    },
    rivertyClientId: {
      type: "string",
      minLength: 1,
      maxLength: 255,
    },
    rivertyInvoiceFee: {
      type: "integer",
      minimum: 0,
    },
    providers: {
      type: "array",
      items: openApiProviderAssignmentBase,
    },
  },
};

export type OperatorSettings = OperatorDb & {
  providers: {
    id: string;
    name: string;
    isIntegrated: boolean;
  }[];
};
